import { useState } from "react";
import { TitleHeader, BlogEditor } from "../components";

export default function Editor() {
  const [type] = useState("blogs");
  return (
    <div>
      <div className="x-editor-screen">
        <TitleHeader
          title={"Editor"}
          details="Owner is responsible for his valuables"
        />
        {/*<div className="x-switch-button-container">
          <Button className="x-switch-button" onClick={() => setType("blogs")}>
            Blogs
          </Button>
  </div>*/}
        {type === "blogs" && <BlogEditor />}
        {type === "" && <div>Select something to edit</div>}
      </div>

      <style jsx>
        {`
          .x-switch-button-container {
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .x-switch-button {
            margin-right: 20px;
          }
        `}
      </style>
    </div>
  );
}
