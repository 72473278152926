import { useEffect, useState } from "react";
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ScrollToTop,
  SiteSeo,
  Header,
  Footer,
  LoaderOverlay,
  ScrollToTopButton,
  MainPopup,
} from "./components";

function App() {
  const [popup, setPopup] = useState(false);

  function showPopup() {
    setPopup(true);
  }

  function onClosePopup() {
    setPopup(false);
  }

  useEffect(() => {
    setTimeout(() => showPopup(), 3000);
  }, []);

  return true ? (
    <Router>
      {/*<ScrollToTopButton />*/}
      <ScrollToTop />
      <SiteSeo />
      {/*<MainPopup
        openState={[popup, setPopup]}
        onClose={onClosePopup}
        path={"/contact"}
      />*/}
      <Routes />
      <Footer />
    </Router>
  ) : (
    <LoaderOverlay isOpen={true} />
  );
}

export default App;
