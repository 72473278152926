import { TitleHeader, ProjectDetailsBlock } from "../components";
import React, { useState, useEffect, Fragment } from "react";

import { useParams } from "react-router-dom";
import getProjects from "../commands/getProjects";
import { LoaderOverlay, Block404, ErrorBlock, Header } from "../components";
import getFileName from "../commands/core/getFileName";

export default function ProjectDetails() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show404, setShow404] = useState(false);
  const [showError, setShowError] = useState(false);
  //const data = commands.blogs.getBlogs({ pageId: params.slug });
  var projectId = params.slug?.split("-").pop();
  useEffect(() => {
    getProjects({ id: projectId, onFail })
      .then((data) => {
        setData([data.results]);
      })
      .finally(() => setLoading(false));

    function onFail(type) {
      if (type === "404") {
        setShow404(true);
      } else {
        setShowError(true);
      }
    }
  }, [projectId]);

  if (show404) return <Block404 />;
  if (showError) return <ErrorBlock />;
  if (data.length < 1 || loading) return <LoaderOverlay isOpen={true} />;

  const item = data[0];

  console.log("sentdata ", item);
  return (
    <Fragment>
      <Header />
      <div className="x-project-details-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          {item.title}
        </h1>
        <TitleHeader
          title={item.title}
          details={item.sub_title}
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${item.thumbnail_url})`,
          }}
          altText={getFileName(item.thumbnail)}
        />
        <ProjectDetailsBlock data={item} />
      </div>
    </Fragment>
  );
}
