import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import "./css/paginator.css";
import "./css/quill.bubble.css";
import "./css/quill.core.css";
import "./css/quill.snow.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Suspense, Fragment } from "react";
import 'daemonite-material/css/material.min.css';
import 'daemonite-material/js/material.min.js';
import "aos/dist/aos.css";
import AOS from "aos";
import { ToastProvider } from "react-toast-notifications";
import Image from "./components/image";

window.addEventListener("load", function () {
  AOS.init();
});

const Loader = (
  <Fragment>
    <style>
      {`.x-load-block {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1099;
      }

      .x-load-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 1;
      }

      .x-load-loading-image {
        height: auto;
        width: 15.706449vw;
        object-fit: contain;
        z-index: 1;
      }

      @media (max-width: 1200px) {

        .x-load-loading-image {
          width: 45.114583vw;
        }
      }

      @media (max-width: 767.88px) {

        .x-load-loading-image {
          width: 61.077295vw;
        }
      }`}
    </style>
    <div className="x-load-block">
      <Image
        src="loading.gif"
        alt="Loading Please Wait..."
        className="x-load-loading-image"
      />
      <div className="x-load-overlay"></div>
    </div>
  </Fragment>
);

ReactDOM.render(
      <ToastProvider autoDismissTimeout={10000} autoDismiss={true}>
        <Suspense fallback={Loader}>
          <App />
        </Suspense>
      </ToastProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
