import { useState, useEffect } from "react";
import Config from "../config";
export default function useImage(src, external) {
  const [source, setSource] = useState("");
  const srcUrl = external ? src : Config.assetUrl + src;

  useEffect(() => {
    var image = new Image();
    image.onload = function () {
      setSource(image.src);
    };
    image.src = srcUrl;
  }, [srcUrl]);

  return source;
}
