import { Route, Switch } from "react-router-dom";
import Block404 from "../components/404-block";

import Home from "../screens/home";
import About from "../screens/about";
import Projects from "../screens/projects";
import ProjectDetails from "../screens/project-details";
import Services from "../screens/services";
import Contact from "../screens/contact";
import ComingSoon from "../screens/coming-soon";
import BlogEditor from "../screens/blog-editor";
import Blogs from "../screens/blogs";
import Care from "../screens/care";
import BlogDetails from "../screens/blog-details";
import GetEstimate from "../screens/get-estimate";
import Editor from "../screens/editor";

const screens = {
  Home,
  About,
  Projects,
  ProjectDetails,
  Services,
  Contact,
  ComingSoon,
  BlogEditor,
  Blogs,
  BlogDetails,
  Care,
  GetEstimate,
  Editor,
};

const Routes = () => (
  <Switch>
    <Route
      path={RoutePaths["PRICING-ESTIMATOR"]}
      component={screens.ComingSoon || GetEstimate}
    />
    <Route path={RoutePaths["EDITOR"]} component={screens.Editor} />
    <Route path={RoutePaths["COMING-SOON"]} component={screens.ComingSoon} />
    <Route path={RoutePaths["CARE"]} component={screens.Care} />
    <Route path={RoutePaths["BLOG-DETAILS"]} component={screens.BlogDetails} />
    <Route path={RoutePaths["BLOGS"]} component={screens.Blogs} />
    <Route path={RoutePaths["BLOG-EDITOR"]} component={screens.BlogEditor} />
    <Route path={RoutePaths["CONTACT"]} component={screens.Contact} />
    <Route path={RoutePaths["SERVICE-DETAILS"]} component={screens.Services} />
    <Route path={RoutePaths["SERVICES"]} component={screens.Services} />
    <Route
      path={RoutePaths["PROJECT-DETAILS"]}
      component={screens.ProjectDetails}
    />
    <Route path={RoutePaths["PROJECTS"]} component={screens.Projects} />
    <Route path={RoutePaths["ABOUT"]} exact component={screens.About} />
    <Route path={RoutePaths["HOME"]} exact component={screens.Home} />
    <Route component={Block404} />
  </Switch>
);

export const RoutePaths = {
  "COMING-SOON": "/coming-soon",
  HOME: "/",
  ABOUT: "/about",
  PROJECTS: "/projects",
  "PROJECT-DETAILS": "/projects/:slug",
  "SERVICE-DETAILS": "/services/:slug",
  SERVICES: "/services",
  CONTACT: "/contact",
  CARE: "/care",
  "PRICING-ESTIMATOR": "/pricing-estimator",
  INQUIRY: "/pricing-estimator",
  "BLOG-EDITOR": "/blogs/editor",
  BLOGS: "/blogs",
  "BLOG-DETAILS": "/blogs/:slug",
  EDITOR: "/editor",
};

/*

Home : http://adishreeconstruction.com/
About : http://adishreeconstruction.com/about
Projects : http://adishreeconstruction.com/projects
Project Details : http://adishreeconstruction.com/projects/anything
Services : http://adishreeconstruction.com/services
Contact : http://adishreeconstruction.com/contact

*/

export default Routes;
