import { Fragment } from "react";
import {
  Header,
  HeroBlock,
  ProjectsBlock,
  TestimonialBlock,
  PreferredChoiceBlock,
  ProcessBlock,
  ServicesBlock,
  TestimonialGridBlock,
} from "../components";

export default function Home() {
  return (
    <Fragment>
      <Header />
      <div className="x-home-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          Construction and real estate company - Adishree Construction
        </h1>
        <HeroBlock />
        <PreferredChoiceBlock />
        <ServicesBlock />
        <ProcessBlock />
        <ProjectsBlock />
        {/*<TestimonialBlock />*/}
        {/*<TestimonialGridBlock />*/}
      </div>
    </Fragment>
  );
}
