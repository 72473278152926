import config from "../config";

//const config = { baseApiUrl: "http://localhost", apiUrl: "http://localhost/api/v1" };

function checkUrl(url) {
  if (url !== "" && typeof url === "string") {
    if (url.substr(-1) !== "/") {
      url += "/";
    }
  }
  return url;
}

const getApiUrl = (endpoint = "") => checkUrl(config.apiUrl) + endpoint;
//const getbaseApiUrl = (endpoint = "") => checkUrl(config.baseApiUrl) + endpoint;

export const sendContactUsData = () => ({
  method: "POST",
  url: getApiUrl(`contact-us`),
  params: {},
  body: {
    email: "",
    full_name: "",
    phone_number: "",
    subject: "",
    description: "",
  },
  headers: {},
  bearerToken: "",
});

export const sendInquiryData = () => ({
  method: "POST",
  url: getApiUrl(`inquiry`),
  params: {},
  body: {
    //new FormData();
    email: "",
    subject: "",
    filename: "", //file object
    url: "",
  },
  headers: {
    "Content-Type": "multipart/form-data",
  },
  bearerToken: "",
});

export const getClientReviews = () => ({
  method: "GET",
  url: getApiUrl(`client-review`),
  params: {
    page: 1,
    per_page: 9,
    sort: "dsc",
    sort_by: "row_id",
  },
  body: {},
  headers: {},
  bearerToken: "",
});

export const getBlogs = (id = "") => ({
  method: "GET",
  url: id ? getApiUrl(`blogs/${id}`) : getApiUrl(`blogs`),
  params: {
    tags: "",
    page: 1,
    per_page: 10,
    sort: "dsc",
    sort_by: "row_id",
  },
  body: {},
  headers: {},
  bearerToken: "",
});

export const addBlogs = () => ({
  method: "POST",
  url: getApiUrl(`blogs`),
  params: {},
  body: {
    author_avatar: "",
    author_name: "",
    author_occupation: "",
    category: "",
    content: "",
    sub_title: "",
    thumbnail: "",
  },
  headers: {},
  bearerToken: "",
});

export const updateBlogs = (id = "") => ({
  method: "PUT",
  url: getApiUrl(`blogs/${id}`),
  params: {},
  body: {
  },
  headers: {},
  bearerToken: "",
});

export const getProjects = (id = "") => ({
  method: "GET",
  url: id ? getApiUrl(`project/${id}`) : getApiUrl(`project`),
  params: {
    page: 1,
    per_page: 9,
    sort: "dsc",
    sort_by: "row_id",
  },
  body: {},
  headers: {},
  bearerToken: "",
});

export const getEvents = () => ({
  method: "GET",
  url: getApiUrl(`gallery`),
  params: {
    tags: "holi",
    page: 1,
    per_page: 8,
    sort: "dsc",
    sort_by: "row_id",
  },
  body: {},
  headers: {},
  bearerToken: "",
});

export const getMetaData = () => ({
  method: "GET",
  url: getApiUrl(`placeholder`),
  params: {
    blog_id: undefined,
    url: undefined,
  },
  body: {},
  headers: {},
  bearerToken: "",
});
