import service, { serviceTypes } from "../services";

export default async function getBlogs({
  id = "",
  page = 1,
  perPage = 10,
  onFail,
}) {
  const serviceType = serviceTypes.getBlogs(id);

  serviceType.params.page = page;
  serviceType.params.per_page = perPage;
  if (!id) {
    var params = new URLSearchParams(
      `?page=${page}&per_page=${perPage}&pfields=author_avatar_url&pfields=author_name&pfields=row_id&pfields=thumbnail_url&pfields=title&pfields=sub_title&pfields=created_date&sort=dsc&sort_by=created_date&is_publish=true&sort=dsc&sort_by=created_date`
    );
    serviceType.urlSearchParams = params;
  }

  const { response, error } = await service(serviceType);

  if (error) {
    if (error.type === "404") {
      typeof onFail === "function" && onFail("404");
    } else {
      typeof onFail === "function" && onFail();
    }
  } else if (
    Array.isArray(response.data?.results) &&
    ("total" in response.data || id !== "")
  ) {
    return response.data;
  }

  return { total: 0, results: [] };
}
