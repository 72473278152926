import { Fragment } from "react";
import {
  TitleHeader,
  SomeBrandsBlock,
  ProjectsBlock,
  Header,
} from "../components";
import Config from "../config";

export default function Projects() {
  return (
    <Fragment>
      <Header />
      <div className="x-projects-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          Projects
        </h1>
        <TitleHeader
          title="Projects"
          details="We do not aim to meet our client's expectations; we strive to exceed them, completing every job to the highest possible standard. Therefore, we have included a small selection of our work here to see the quality for yourself."
          style={{
            backgroundImage: `url(${Config.assetUrl}screens/projects/header-background.png)`,
          }}
          altText={"projects by adishree constructions"}
        />
        <SomeBrandsBlock />
        <ProjectsBlock isProjectsPage={false} />
      </div>
    </Fragment>
  );
}
