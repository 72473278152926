import { Fragment } from "react";
import { TitleHeader, ContactMap, ContactForm, Header } from "../components";
import Config from "../config";
export default function Contact() {
  return (
    <Fragment>
      <Header />
      <div className="x-contact-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          Contact
        </h1>
        <TitleHeader
          title="Contact Us"
          details=""
          style={{
            backgroundImage: `url(${Config.assetUrl}screens/contact/header-background.png)`,
          }}
          altText={"real estate consultant"}
        />
        <ContactForm />
        <ContactMap />
      </div>
    </Fragment>
  );
}

/*

Email
FUll name
phone number
subject
description
*/
