import React from "react";

import "./index.scss";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "../../routes";
export default function Block404() {
  const history = useHistory();

  return (
    <div className="x-404-block-component">
      <div className="x-content-wrap">
        <div className="x-title">404</div>
        <div className="x-description">
          Sorry. the content you’re looking for doesn’t exist. Either it was
          removed, or you mistyped the link.
        </div>
        <div className="x-buttons">
          <Button
            className="x-btn-green"
            onClick={() => history.push(RoutePaths["HOME"])}
          >
            Go to Homepage
          </Button>
          <Button
            className="x-btn-white"
            onClick={() => history.push(RoutePaths["CONTACT"])}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
}
