import { Fragment } from "react";
import { TitleHeader, GetEstimateBlock, Header } from "../components";
import Config from "../config";

export default function GetEstimate() {
  return (
    <Fragment>
      <Header backgroundPad={true}/>
      <div className="x-get-estimate-screen">
        <GetEstimateBlock />
      </div>
    </Fragment>
  );
}
