import { Fragment } from "react";
import { TitleHeader, CareBlock,Header } from "../components";
import Config from "../config";
export default function Care() {
  return (
    <Fragment>
      <Header />
    <div className="x-care-screen">
    <h1
      style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
    >
      Adishree Care
    </h1>
      <TitleHeader
        title="Adishree Care"
        details="The modern and yet luxurious property makes for the ultimate residential & commercial experience. We are situated in Punjab, India."
        style={{
          backgroundImage: `url(${Config.assetUrl}screens/care/header-background.png)`,
        }}
        altText={"architectural design company"}
      />
      <CareBlock />
    </div>
    </Fragment>
  );
}
