import { Fragment } from "react";
import {
  TitleHeader,
  PushLimitsBlock,
  SeniorDesignerBlock,
  Header,
} from "../components";
import Config from "../config";
import useImage from "../hooks/useImage";
export default function About() {
  const TitleBackgroundImage = useImage(`screens/about/header-background.png`);
  return (
    <Fragment>
      <Header />
      <div className="x-about-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          About Us
        </h1>
        <TitleHeader
          title="About"
          details="At Adishree, we consider that infrastructure creates an opportunity for everyone to improve access, uplift communities, and sustain our planet."
          style={{
            backgroundImage: `url(${TitleBackgroundImage})`,
          }}
          altText={"commercial and residential construction service"}
        />
        <PushLimitsBlock />
        <SeniorDesignerBlock
          title={"Together we build success."}
          content={
            <div>
              <h2
                style={{
                  height: 0.001,
                  width: 0.001,
                  overflow: "hidden",
                  margin: 0,
                }}
              >
                Together we build success.
              </h2>
              We covered from small renovations to complex billion-dollar
              projects, from modular construction to advanced digital
              technologies to cutting-edge sustainability. We redefine
              full-service & bring expertise across all project types, at all
              orders, and under all acquisition models. As innovative solution
              providers & construction experts, we leverage our expertise to
              customize the right plan for your project, maximizing profit for
              the best price.
              <br />
              Flexible, innovative, and focused: we're focused on achieving your
              project vision & business goals.
            </div>
          }
        />
      </div>
    </Fragment>
  );
}
