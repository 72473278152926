import * as serviceTypes from './serviceTypes';
import fetcher from "./fetcher";

async function service(serviceType) {
    var errorObject = {};

    //request interceptor

    //request to server
    const { response, error } = await fetcher(serviceType);

    //response interceptor
    if (error) {
        if (error.response) {
            errorObject.message = "Server error occured";
            if (error.response.status === 404) {
                errorObject.type = "404";
            }
        } else {
            errorObject.message = error.message;
        }
        return { error: errorObject };
    } else if (response) {
        return { response };
    } else {
        return { error: { message: "Unknown Error Occured" } };
    }
}

export default service;
export { serviceTypes, fetcher };
