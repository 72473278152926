import React, { useState, useEffect, Fragment } from "react";
import {
  BlogTitleHeader,
  BlogContent,
  RelatedBlogs,
  Header,
} from "../components";

import { useParams } from "react-router-dom";
import getBlogs from "../commands/getBlogs";
import { LoaderOverlay, Block404, ErrorBlock } from "../components";
import getFileName from "../commands/core/getFileName";

export default function BlogDetails() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [relatedBlogsData, setRelatedBlogsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show404, setShow404] = useState(false);
  const [showError, setShowError] = useState(false);
  //const data = commands.blogs.getBlogs({ pageId: params.slug });
  var blogId = params.slug?.split("-").pop();
  useEffect(() => {
    setLoading(true);
    getBlogs({ id: blogId, onFail })
      .then((data) => {
        setData(data.results);
      })
      .finally(() => setLoading(false));

    function onFail(type) {
      if (type === "404") {
        setShow404(true);
      } else {
        setShowError(true);
      }
    }
  }, [blogId]);

  useEffect(() => {
    //if (data.length > 0 && data[0].tags?.length > 0) {
    // getRelatedBlogs({ tags: data[0].tags[0] }).then((data) => {
    getBlogs({ page: 1, perPage: 10 }).then((data) => {
      console.log("pukpuk ", data);
      setRelatedBlogsData(data.results);
    });
  }, []);

  if (show404) return <Block404 />;
  if (showError) return <ErrorBlock />;
  if (data.length < 1 || loading) return <LoaderOverlay isOpen={true} />;

  const item = data[0];

  return (
    <Fragment>
      <Header />
      <div className="x-blogs-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          {item.title}
        </h1>
        <BlogTitleHeader
          title={item.title}
          details={item.sub_title}
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${item.thumbnail_url})`,
          }}
          titleStyle={{ textAlign: "center" }}
          detailsStyle={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          item={item}
          altText={getFileName(item.thumbnail)}
        />
        <BlogContent
          item={item}
          content={item.content}
          relatedBlogs={relatedBlogsData}
        />
        <RelatedBlogs items={relatedBlogsData} />
      </div>
    </Fragment>
  );
}
