import service, { serviceTypes } from "../services";

export default async function getProjects({ id = "", page = 1, perPage = 10 }) {
  const serviceType = serviceTypes.getProjects(id);
  serviceType.params.page = page;
  serviceType.params.per_page = perPage;

  const { response, error } = await service(serviceType);

  if (error) {
  } else if (
    (Array.isArray(response.data?.results) && "total" in response.data) ||
    id !== ""
  ) {
    return response.data;
  }

  return { total: 0, results: [] };
}
