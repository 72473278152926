import { Fragment } from "react";
import { TitleHeader, BlogsListing, Header } from "../components";
import Config from "../config";
import useImage from "../hooks/useImage";
export default function Blogs() {
  const TitleBackgroundImage = useImage(`screens/blogs/header-background.png`);

  return (
    <Fragment>
      <Header />
      <div className="x-blogs-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          Blogs
        </h1>
        <TitleHeader
          tleHeader
          title="Our Blogs"
          details=""
          style={{
            backgroundImage: `url(${TitleBackgroundImage})`,
          }}
          titleStyle={{ textAlign: "center" }}
          detailsStyle={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          altText={"architectural design company"}
        />
        <BlogsListing />
      </div>
    </Fragment>
  );
}
