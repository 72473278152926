import { Fragment } from "react";
import Image from "../components/image";
import { Header } from "../components";

export default function ComingSoon() {
  return (
    <Fragment>
      <Header />
      <div>
        <style jsx>
          {`
            .x-coming-soon-screen {
              background-color: var(--x-secondary);
              height: 600px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--x-primary);
              font-size: 24px;
              flex-direction: column;
            }

            .x-coming-soon-screen-logo {
              object-fit: contain;
              width: 200px;
              margin-bottom: 50px;
            }
          `}
        </style>
        <div className="x-coming-soon-screen">
          <Image
            src={"components/header/logo.png"}
            alt="adishree"
            className="x-coming-soon-screen-logo"
          />
          <div>Coming soon...</div>
        </div>
      </div>
    </Fragment>
  );
}
