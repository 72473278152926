import { lazy } from "react";

const ScrollToTop = lazy(() => import("./scroll-to-top"));
const Block404 = lazy(() => import("./404-block"));
const LoaderOverlay = lazy(() => import("./loader-overlay"));
const ErrorBlock = lazy(() => import("./error-block"));
const SiteSeo = lazy(() => import("./site-seo"));
const ScrollToHash = lazy(() => import("./scroll-to-hash"));
const Image = lazy(() => import("./image"));

const Header = lazy(() => import("./header"));
const Footer = lazy(() => import("./footer"));
const HeroBlock = lazy(() => import("./hero-block"));
const ProjectsBlock = lazy(() => import("./projects-block"));
const TestimonialBlock = lazy(() => import("./testimonial-block"));
const PreferredChoiceBlock = lazy(() => import("./preferred-choice-block"));
const ProcessBlock = lazy(() => import("./process-block"));
const TitleHeader = lazy(() => import("./title-header"));
const PushLimitsBlock = lazy(() => import("./push-limits-block"));
const SomeBrandsBlock = lazy(() => import("./some-brands-block"));
const ProjectDetailsBlock = lazy(() => import("./project-details-block"));
const SeniorDesignerBlock = lazy(() => import("./senior-designer-block"));
const PlanningDesignBlock = lazy(() => import("./planning-design-block"));
const ContactMap = lazy(() => import("./contact-map"));
const ContactForm = lazy(() => import("./contact-form"));
const ServicesBlock = lazy(() => import("./services-block"));
const TestimonialGridBlock = lazy(() => import("./testimonial-grid-block"));
const GetEstimateBlock = lazy(() => import("./get-estimate-block"));
const MainPopup = lazy(() => import("./main-popup"));

const BlockEditor = lazy(() => import("./block-editor"));
const BlogsListing = lazy(() => import("./blogs-listing"));
const CareBlock = lazy(() => import("./care-block"));
const BlogTitleHeader = lazy(() => import("./blog-title-header"));
const BlogContent = lazy(() => import("./blog-content"));
const RelatedBlogs = lazy(() => import("./related-blogs"));
const BlogEditor = lazy(() => import("./blog-editor"));
const ScrollToTopButton = lazy(() => import("./scroll-to-top-button"));

export {
  ScrollToTop,
  LoaderOverlay,
  ErrorBlock,
  SiteSeo,
  ScrollToHash,
  Block404,
  Image,
  Header,
  Footer,
  HeroBlock,
  ProjectsBlock,
  TestimonialBlock,
  PreferredChoiceBlock,
  ProcessBlock,
  TitleHeader,
  PushLimitsBlock,
  SomeBrandsBlock,
  ProjectDetailsBlock,
  SeniorDesignerBlock,
  PlanningDesignBlock,
  ContactMap,
  ContactForm,
  BlockEditor,
  BlogsListing,
  CareBlock,
  BlogTitleHeader,
  BlogContent,
  RelatedBlogs,
  BlogEditor,
  ScrollToTopButton,
  ServicesBlock,
  TestimonialGridBlock,
  GetEstimateBlock,
  MainPopup,
};
