const Config = {
  get environment() {
    return "production" || "test";
  },
  get baseApiUrl() {
    return "https://adishree-backend.caprover.a2zwebsolution.com";
    return "https://api.adishreeconstruction.com";
  },
  get apiUrl() {
    return Config.baseApiUrl + "/api/v1.0";
  },
  get assetUrl() {
    if (this.environment === "test") {
      return "/images/";
    } else {
      return "https://adishreeconstructionllp.s3.amazonaws.com/app-assets/images/";
    }
  },
  get blogsSocialShareLinks() {
    return {
      facebook:
        "https://www.facebook.com/Adishree-Construction-127860956151164",
      linkedIn: "https://www.linkedin.com/in/adishree-construction-39bbb0218/",
      twitter: "https://twitter.com/AdishreeConstr1",
      instagram: "https://www.instagram.com/adishreeconstructions/",
    };
  },
  get socialShareLinks() {
    return {
      facebook:
        "https://www.facebook.com/Adishree-Construction-127860956151164",
      linkedin: "https://www.linkedin.com/in/adishree-construction-39bbb0218/",
      twitter: "https://twitter.com/AdishreeConstr1",
      instagram: "https://www.instagram.com/adishreeconstruction/",
      pinterest: "https://in.pinterest.com/adishreeconstruction/_saved/",
      quora: "https://www.quora.com/profile/Information-Adishree-Construction",
    };
  },
};

export default Config;
