import { Fragment } from "react";
import { TitleHeader, PlanningDesignBlock, Header } from "../components";
import Config from "../config";

export default function Services() {
  return (
    <Fragment>
      <Header />
      <div className="x-services-screen">
        <h1
          style={{ height: 0.001, width: 0.001, overflow: "hidden", margin: 0 }}
        >
          Services
        </h1>
        <TitleHeader
          title="Services"
          details="We provide various construction services in the commercial and residential sector like architectural design and planning, interior design, renovation, real estate construction, etc."
          style={{
            backgroundImage: `url(${Config.assetUrl}screens/services/header-background.png)`,
          }}
          altText={"construction service in zirakpur"}
        />
        <PlanningDesignBlock />
      </div>
    </Fragment>
  );
}
