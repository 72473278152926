import LazyLoad from "react-lazyload";
import Config from "../../config";
import React, { useState } from "react";

function Image({ lazy, lazyPrefix, src, alt, external, ...props }) {
  const [loaded, setLoaded] = useState(false);
  const srcUrl = external ? src : Config.assetUrl + src;
  const Img = () => (
    <img
      {...props}
      src={srcUrl}
      alt={alt || ""}
      onLoad={() => setLoaded(true)}
      style={{ visibility: loaded ? "visible" : "hidden" }}
    />
  );

  return lazy ? (
    <LazyLoad
      classNamePrefix={
        lazy && typeof lazyPrefix === "string" ? lazyPrefix : undefined
      }
    >
      <Img />
    </LazyLoad>
  ) : (
    <Img />
  );
}

export default React.memo(Image, () => true);
